var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"d-flex flex-row container"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('h3',{staticClass:"font-weight-bold blue-grey--text font text-uppercase font-weight-medium font-md"},[_vm._v(" All Staff ")])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-card',{staticClass:"pa-2",staticStyle:{"height":"120px"},attrs:{"outlined":"","tile":""}},[_c('div',{staticClass:"createNew"},[_c('v-btn',{staticClass:"font font-md",attrs:{"to":{ name: 'admin.staffs.add' },"color":"primary"}},[_vm._v(" Add New Staff ")])],1),_c('div',{staticClass:"container"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Search Staff.....","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)])],1)],1),_c('v-row',{staticClass:"d-flex flex-row container",staticStyle:{"margin-top":"-80px","margin-right":"10px"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12","md":"12","xl":"12"}},[_c('v-toolbar',{staticClass:"mb-6 title mt-2",attrs:{"color":"transparent","flat":"","elevation":"0","height":"55px"}}),_c('v-data-table',{staticClass:"font box-shadow-light font-weight-medium text-capitalize  mx-1 mt-n2",attrs:{"headers":_vm.headers,"items":_vm.staffList,"loading":_vm.pageLoading,"server-items-length":_vm.paginate.total,"options":_vm.options,"loading-text":"Loading... Please wait","search":_vm.search,"footer-props":{
          itemsPerPageOptions: [10, 15, 20]
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
        var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-1 font",attrs:{"color":"primary","size":"40"}},[_c('span',{staticClass:"white--text subtitle-1 "},[_vm._v(_vm._s(_vm._f("firstLetterFunction")(item.name)))])])]}},{key:"item.accountNumber",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","color":"primary"}},[_c('span',{staticClass:" font font-md"},[_vm._v(_vm._s(item.accountNumber))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{
                  name: 'admin.staffs.add',
                  query: {
                    id: item._id,
                    action: 'edit'
                  }
                },"icon":"","fab":"","small":""}},on),[_c('i',{staticClass:"material-icons-outlined font-md"},[_vm._v("edit")])])]}}],null,true)},[_c('span',{staticClass:" font font-sm"},[_vm._v("Edit Staff Member")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.isLoading,"disabled":_vm.profile.id === item._id,"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.$store.dispatch('staffs/delete', item._id)}}},on),[_c('i',{staticClass:"material-icons-outlined font-md"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:" font font-sm"},[_vm._v("Delete Staff Member")])])]}}])})],1)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }